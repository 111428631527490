import React from 'react';
import { Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { createBrowserHistory } from 'history';
import CssBaseline from '@material-ui/core/CssBaseline';
import i18nProvider from './i18n/i18nProvider';
import authProvider from './providers/authProvider';

import Routes from './routes/Routes';
import { Layout } from './pages/Layout';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';

import { rootSaga, rootReducer } from './store';
import initialState from './constants/initialState';
import './styles/scss/theme.scss';

const history = createBrowserHistory();
const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

const App = () => {
  return (
    <>
      <CssBaseline />
      <Admin
        title="Shareitt Admin"
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        loginPage={LoginPage}
        dashboard={Dashboard}
        customSagas={rootSaga}
        customReducers={rootReducer}
        layout={Layout}
        customRoutes={Routes}
        initialState={initialState}
        history={history}
      >
        <Resource name="Community Info"/>
      </Admin>
    </>
  );
};

export default App;
