// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.PageContent_contentWrapper__3W9Rd {\n  width: inherit;\n  position: relative;\n  flex-grow: 1;\n}\n\n[dir] .PageContent_contentWrapper__3W9Rd {\n  padding: 40px 40px 60px;\n}\n@media (max-width: 767.98px) {\n  .PageContent_contentWrapper__3W9Rd {\n    min-width: 100vw;\n  }\n  [dir] .PageContent_contentWrapper__3W9Rd {\n    padding: 20px 15px 70px;\n  }\n}\n@media (min-width: 576px) {\n  .PageContent_contentWrapper__3W9Rd {\n    -webkit-user-select: auto !important;\n            user-select: auto !important;\n  }\n}\n\n.PageContent_titleWrapper__1uU45 {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: baseline;\n}\n\n[dir] .PageContent_titleWrapper__1uU45 {\n  margin: 0 0 calc(1rem + 6px);\n}\n\n.PageContent_pageTitle__wmO_Z {\n  font-family: \"Montserrat\", sans-serif;\n}\n\n.PageContent_fwSemiBold__3Ns6B {\n  font-weight: 600;\n}", ""]);
// Exports
exports.locals = {
	"contentWrapper": "PageContent_contentWrapper__3W9Rd",
	"titleWrapper": "PageContent_titleWrapper__1uU45",
	"pageTitle": "PageContent_pageTitle__wmO_Z",
	"fwSemiBold": "PageContent_fwSemiBold__3Ns6B"
};
module.exports = exports;
