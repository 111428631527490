// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.ReportsContent_gridWrapper__xKpbs {\n  grid-template: 1fr/1fr;\n  grid-gap: 1rem;\n}\n\n.ReportsContent_chartsWrapper__RAds4 {\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  grid-gap: 1rem;\n}\n\n.ReportsContent_tableWidget__2zlG1 {\n  overflow-x: scroll;\n  -webkit-overflow-scrolling: touch;\n}\n@media (min-width: 768px) {\n  .ReportsContent_tableWidget__2zlG1 {\n    max-width: 500px;\n  }\n  [dir] .ReportsContent_tableWidget__2zlG1 {\n    margin: 0 auto;\n  }\n}\n\n.ReportsContent_avatar__L3B-K {\n  width: 25px;\n  height: 25px;\n  object-fit: cover;\n}\n\n[dir] .ReportsContent_avatar__L3B-K {\n  border-radius: 50%;\n}\n\n[dir=ltr] .ReportsContent_avatar__L3B-K {\n  margin-right: 14px;\n}\n\n[dir=rtl] .ReportsContent_avatar__L3B-K {\n  margin-left: 14px;\n}", ""]);
// Exports
exports.locals = {
	"gridWrapper": "ReportsContent_gridWrapper__xKpbs",
	"chartsWrapper": "ReportsContent_chartsWrapper__RAds4",
	"tableWidget": "ReportsContent_tableWidget__2zlG1",
	"avatar": "ReportsContent_avatar__L3B-K"
};
module.exports = exports;
