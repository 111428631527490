// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.TalentsTable_infoWrapper__30582 {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-start;\n  align-items: center;\n  grid-gap: 0.5rem;\n  gap: 0.5rem;\n}\n\n.TalentsTable_infoText__2MbR9 {\n  display: flex;\n  flex-flow: column nowrap;\n  grid-gap: 0.5rem;\n  gap: 0.5rem;\n}\n\n.TalentsTable_avatar__MCQzk {\n  width: 25px;\n  height: 25px;\n  object-fit: cover;\n}\n\n[dir] .TalentsTable_avatar__MCQzk {\n  border-radius: 50%;\n}\n\n[dir=ltr] .TalentsTable_avatar__MCQzk {\n  margin-right: 14px;\n}\n\n[dir=rtl] .TalentsTable_avatar__MCQzk {\n  margin-left: 14px;\n}\n\n.TalentsTable_itemImage__2g8tG {\n  width: 75px;\n  height: 75px;\n  object-fit: contain;\n}\n\n[dir] .TalentsTable_itemImage__2g8tG {\n  border-radius: 2px;\n}\n\n[dir=ltr] .TalentsTable_itemImage__2g8tG {\n  margin-right: 14px;\n}\n\n[dir=rtl] .TalentsTable_itemImage__2g8tG {\n  margin-left: 14px;\n}\n\n.TalentsTable_multipleSupplyIcon__3jDf5::before {\n  font-size: 2rem;\n}\n\n.TalentsTable_searchWrapper__1S2oP {\n  display: grid;\n  grid-template: 1fr/repeat(3, 1fr);\n  grid-gap: 1rem;\n}\n\n.TalentsTable_searchPanel__3vsum {\n  grid-column: 3;\n}", ""]);
// Exports
exports.locals = {
	"infoWrapper": "TalentsTable_infoWrapper__30582",
	"infoText": "TalentsTable_infoText__2MbR9",
	"avatar": "TalentsTable_avatar__MCQzk",
	"itemImage": "TalentsTable_itemImage__2g8tG",
	"multipleSupplyIcon": "TalentsTable_multipleSupplyIcon__3jDf5",
	"searchWrapper": "TalentsTable_searchWrapper__1S2oP",
	"searchPanel": "TalentsTable_searchPanel__3vsum"
};
module.exports = exports;
