import { AxiosResponse } from 'axios';
import api from '../../api/Api';

const getSubcommunities = (communityId) => {
  return api.get(`v2/admin/community/${communityId}/sub-communities`) || [];
};

const addSubcommunity = (communityId, data) => {
  return api.post(`v2/admin/community/${communityId}/sub-community`, data);
};

const updateSubcommunity = (communityId, subcommunityId, data) => {
  return api.patch(`v2/admin/community/${communityId}/sub-community/${subcommunityId}`, data);
};

export default {
  getSubcommunities,
  addSubcommunity,
  updateSubcommunity,
};
