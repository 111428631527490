export const rulesKinds = {
  community: 'community',
  item: 'item',
  wish: 'wish',
};

export const defaultGroupForSelect = {
  value: null,
  label: {
    en: 'Select group',
    he: 'בחר קבוצה',
  },
  icon: '',
};

export const groupFeaturesKeys = [
  'hasBrands',
  'hasSizes',
  'hasFavorites',
  'hasDiscounts',
  'hasSellerItemRepost',
  'hasBuyerItemRepost',
];

export const communityFeaturesKeys = [
  'isPrivate',
  'isOpenForVisitors',
  'hasFollowers',
  'hasWishes',
  'hasQuestions',
  'hasSlider',
];

export const summaryIds = {
  totalCount: 'totalCount',
  soldCount: 'soldCount',
  marketCount: 'marketCount',
  totalValue: 'totalValue',
  soldValue: 'soldValue',
  marketValue: 'marketValue',
  members: 'members',
  issuedFunds: 'issuedFunds',
  remainingSupply: 'remainingSupply',
};

export const communityInfoFields = [
  'name',
  'about',
  'communityIcon',
  'tokenName',
  'walletBackgroundImage',
];

export const RULE_TYPES = {
  COMMUNITY: 'community',
  ITEM: 'item',
  WISH: 'wish',
};

export const RULE_STATE_FIELDS_MAP_BY_KIND = {
  community: 'communityRulesIds',
  item: 'itemRulesIds',
  wish: 'wishRulesIds',
};

export const LOCALE_TYPES = {
  EN: 'en',
  HE: 'he',
  BR: 'br',
  DE: 'de',
};

export const LOCALE_TYPES_SUFFIX = {
  EN: 'En',
  HE: 'He',
  BR: 'Br',
  DE: 'De',
};

export const LINE_CHART_TYPES = {
  ITEMS: 'items',
  MEMBERS: 'members',
};

export const ENTER_KEY = 'Enter';
