import React, { useEffect, useState } from 'react';
import { useTranslate } from 'ra-core';
import EditableTextarea from '../../../components/EditableTextarea';
import { updateSubcommunity } from '../../../store/subcommunities/actions';
import { useDispatch } from 'react-redux';

const SubcommunityJoinCode = ({ initialValue }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const [joinCode, setJoinCode] = useState(initialValue);

  const isValueChanged = joinCode !== initialValue;

  useEffect(() => {
    setJoinCode(initialValue);
  }, [initialValue]);

  const onSaveJoinCode = async () => {
    const formattedJoinCode = joinCode?.length > 0 ? joinCode : null;
    dispatch(updateSubcommunity({ joinCode: formattedJoinCode }));
  };

  const isSaveButtonDisabled = !isValueChanged;

  return (
    <EditableTextarea
      defaultValue={joinCode}
      value={joinCode}
      label={translate('pages.subcommunities.inputs.joinCode.label')}
      maxLength={30}
      onInputChange={setJoinCode}
      id="subCommunityJoinCode"
      showButtons
      onSave={onSaveJoinCode}
      isSaveButtonDisabled={isSaveButtonDisabled}
    />
  );
};

export default SubcommunityJoinCode;
