import { createSelector } from 'reselect';
import { propOr } from 'ramda';
import { REPORT_REASONS_MAP_BY_ID } from '../../constants/reports';

export const getReportsSelectedTab = (state) => {
  return state.reports.selectedTab;
};

export const getReportIdsByType = (state, type) => {
  return state.reports[type].ids;
};

export const getReportEntitiesByType = (state, type) => {
  return state.reports[type].entities;
};

export const getIsReportsLoadingByType = (state, type) => {
  return state.reports[type].isLoading;
};

export const getIsReportsExistMoreByType = (state, type) => {
  return state.reports[type].isExistMore;
};

export const getReportsByType = createSelector(
  [getReportIdsByType, getReportEntitiesByType],
  (ids, entities) => {
    return ids.map((id) => {
      return entities[id];
    });
  },
);

export const getReportsOffsetByType = createSelector(
  [getReportIdsByType],
  (ids) => {
    return ids.length;
  },
);

export const getTopReports = (state) => {
  return state.reports.topReports;
};

export const getTotalItemReports = (state) => {
  return propOr(0, ['totalItemReports'], getTopReports(state));
};

export const getTotalUserReports = (state) => {
  return propOr(0, ['totalUserReports'], getTopReports(state));
};

export const getTopItemReports = (state) => {
  return propOr([], ['topItemReports'], getTopReports(state));
};

export const getTopUserReports = (state) => {
  return propOr([], ['topUserReports'], getTopReports(state));
};

const getTopReportsChartDataFactory = (dataSelector, totalValueSelector) => {
  return createSelector(
    [dataSelector, totalValueSelector],
    (data, totalValue) => {
      return data.map(({ reportReason, value }) => {
        return {
          name: REPORT_REASONS_MAP_BY_ID[reportReason],
          y: (parseInt(value, 10) / totalValue) * 100,
          z: parseInt(value),
        };
      });
    },
  );
};

export const getTopItemReportsChartData =
  getTopReportsChartDataFactory(getTopItemReports, getTotalItemReports);
export const getTopUserReportsChartData =
  getTopReportsChartDataFactory(getTopUserReports, getTotalUserReports);

export const getTopReportsAnalytics = createSelector(
  [
    getTopItemReportsChartData,
    getTopUserReportsChartData,
    getTotalItemReports,
    getTotalUserReports,
  ],
  (itemData, userData, itemTotal, userTotal) => {
    return {
      item: {
        data: itemData,
        total: itemTotal,
      },
      user: {
        data: userData,
        total: userTotal,
      },
    };
  },
);

export const getReporterEntities = (state) => {
  return state.reports.reporters.entities;
};

export const getItemReporterIds = (state) => {
  return state.reports.reporters.itemIds;
};

export const getUserReporterIds = (state) => {
  return state.reports.reporters.userIds;
};

const getReportersByTypeFactory = (idsSelector) => {
  return createSelector(
    [getReporterEntities, idsSelector],
    (entities, ids) => {
      return ids.map((id) => {
        return entities[id];
      });
    },
  );
};

const getUserReporters = getReportersByTypeFactory(getUserReporterIds);
const getItemReporters = getReportersByTypeFactory(getItemReporterIds);

export const getReporters = createSelector(
  [getUserReporters, getItemReporters],
  (user, item) => {
    return {
      user,
      item,
    };
  },
);

export const getReportedUserEntities = (state) => {
  return state.reports.reportedUsers.entities;
};

export const getUserReportedUserIds = (state) => {
  return state.reports.reportedUsers.userIds;
};

export const getItemReportedUserIds = (state) => {
  return state.reports.reportedUsers.itemIds;
};

const getReportedUsersByTypeFactory = (idsSelector) => {
  return createSelector(
    [getReportedUserEntities, idsSelector],
    (entities, ids) => {
      return ids.map((id) => {
        return entities[id];
      });
    },
  );
};

const getUserReportedUsers = getReportedUsersByTypeFactory(getUserReportedUserIds);
const getItemReportedUsers = getReportedUsersByTypeFactory(getItemReportedUserIds);

export const getReportedUsers = createSelector(
  [getUserReportedUsers, getItemReportedUsers],
  (user, item) => {
    return {
      user,
      item,
    };
  },
);
