import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import * as actions from '../../store/reports/actions';
import { getReportsSelectedTab } from '../../store/reports/selectors';
import { getSelectedCommunityId } from '../../store/community/selectors';
import { REPORT_TABS } from '../../constants/reports';
import PageContent from '../../components/PageContent';
import TabsPanel from '../../components/TabsAccordion/TabsPanel';
import ReportsContent from './ReportsContent';

const Reports = ({
  communityId,
  activeTab,
  setReportsTab,
}) => {
  const translate = useTranslate();
  const tabOptions = [
    {
      value: REPORT_TABS.USER,
      label: translate('pages.reports.labels.types.user'),
    }, {
      value: REPORT_TABS.ITEM,
      label: translate('pages.reports.labels.types.item'),
    }, {
      value: REPORT_TABS.REPORTERS,
      label: translate('pages.reports.labels.types.reporters'),
    }, {
      value: REPORT_TABS.REPORTED_USERS,
      label: translate('pages.reports.labels.types.reportedUsers'),
    }, {
      value: REPORT_TABS.TOP_REPORTS,
      label: translate('pages.reports.labels.types.top'),
    },
  ];

  const onTabClick = (value) => {
    setReportsTab(value);
  };

  useEffect(() => {
    if (!isNil(communityId)) {
      setReportsTab(REPORT_TABS.USER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId]);

  return (
    <PageContent title={translate('pages.reports.title.page')}>
      <TabsPanel
        options={tabOptions}
        activeTab={activeTab}
        onTabClick={onTabClick}
      />

      <ReportsContent
        activeTab={activeTab}
      />
    </PageContent>
  );
};

Reports.propTypes = {
  activeTab: PropTypes.string.isRequired,
  communityId: PropTypes.number,
  setReportsTab: PropTypes.func.isRequired,
};

Reports.defaultProps = {
  communityId: null,
};

export default connect(
  (state) => {
    return {
      communityId: getSelectedCommunityId(state),
      activeTab: getReportsSelectedTab(state),
    };
  }, {
    setReportsTab: actions.setReportsTab,
  },
)(Reports);
