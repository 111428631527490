const prefix = 'reports/';

export const REPORTS_START = `${prefix}REPORTS_START`;
export const REPORTS_SUCCESS = `${prefix}REPORTS_SUCCESS`;

export const REPORTERS_START = `${prefix}REPORTERS_START`;
export const REPORTERS_SUCCESS = `${prefix}REPORTERS_SUCCESS`;

export const REPORTED_USERS_START = `${prefix}REPORTED_USERS_START`;
export const REPORTED_USERS_SUCCESS = `${prefix}REPORTED_USERS_SUCCESS`;

export const TOP_REPORTS_START = `${prefix}TOP_REPORTS_START`;
export const TOP_REPORTS_SUCCESS = `${prefix}TOP_REPORTS_SUCCESS`;

export const SET_REPORTS_TAB = `${prefix}SET_REPORTS_TAB`;

export const SET_IS_LOADING = `${prefix}SET_IS_LOADING`;
