import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'ra-core';
import { deepMerge } from '../../utils/store';
import * as AT from './types';
import { REPORT_TABS } from '../../constants/reports';

const initialState = {
  selectedTab: REPORT_TABS.USER,
  item: {
    entities: {},
    ids: [],
    isLoading: false,
    isExistMore: true,
  },
  user: {
    entities: {},
    ids: [],
    isLoading: false,
    isExistMore: true,
  },
  reporters: {
    entities: {},
    userIds: [],
    itemIds: [],
  },
  reportedUsers: {
    entities: {},
    userIds: [],
    itemIds: [],
  },
  topReports: {},
};

const reducer = handleActions({
  [AT.SET_IS_LOADING]: deepMerge(({ payload }) => {
    return {
      [payload.type]: {
        isLoading: payload.isLoading,
      },
    };
  }),
  [AT.SET_REPORTS_TAB]: deepMerge(({ payload }) => {
    return {
      selectedTab: payload,
    };
  }),
  [AT.REPORTS_SUCCESS]: deepMerge(({ payload }, state) => {
    return {
      [payload.type]: {
        entities: payload.entities,
        ids: payload.isLoadMore
          ? [...state[payload.type].ids, ...payload.ids]
          : payload.ids,
        isExistMore: payload.isExistMore,
      },
    };
  }),
  [AT.REPORTERS_SUCCESS]: deepMerge(({ payload }) => {
    return {
      reporters: payload,
    };
  }),
  [AT.REPORTED_USERS_SUCCESS]: deepMerge(({ payload }) => {
    return {
      reportedUsers: payload,
    };
  }),
  [AT.TOP_REPORTS_SUCCESS]: deepMerge(({ payload }) => {
    return {
      topReports: payload,
    };
  }),
  [CLEAR_STATE]: () => {
    return initialState;
  },
}, initialState);

export default reducer;
