// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.Subcommunity_selectWrapper__1YbJu {\n  display: flex !important;\n  max-width: 200px;\n  height: 50px;\n}\n\n.Subcommunity_selectMenu__30WIq {\n  width: 100%;\n  max-width: 200px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  max-height: 200px;\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n\n.Subcommunity_selectMenu__30WIq::-webkit-scrollbar {\n  display: none; /* Safari and Chrome */\n}\n\n.Subcommunity_selectPlaceholder__ymlKv {\n  display: flex !important;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n  align-items: center;\n  color: #494A4C !important;\n  border: 1px solid #BFC0C2 !important;\n  font-weight: 600 !important;\n}\n\n[dir] .Subcommunity_selectPlaceholder__ymlKv {\n  background-color: #F5F6F7 !important;\n}\n\n.Subcommunity_caret__3haqc {\n  font-size: 2rem !important;\n}\n\n[dir] .Subcommunity_emptyImage__1OB0x {\n  border-radius: 50%;\n  background-color: #798892;\n}", ""]);
// Exports
exports.locals = {
	"selectWrapper": "Subcommunity_selectWrapper__1YbJu",
	"selectMenu": "Subcommunity_selectMenu__30WIq",
	"selectPlaceholder": "Subcommunity_selectPlaceholder__ymlKv",
	"caret": "Subcommunity_caret__3haqc",
	"emptyImage": "Subcommunity_emptyImage__1OB0x"
};
module.exports = exports;
