// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.Separator_separator__2pRDr {\n  width: 100%;\n  height: 3px;\n}\n\n[dir] .Separator_separator__2pRDr {\n  background-color: #d9dde0;\n  margin: 4rem 0;\n}", ""]);
// Exports
exports.locals = {
	"separator": "Separator_separator__2pRDr"
};
module.exports = exports;
