// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.TransactionsTable_buttonsWrapper__1jGvX {\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.TransactionsTable_searchWrapper__3csiw {\n  display: grid;\n  grid-template: 1fr/repeat(3, 1fr);\n  grid-gap: 1rem;\n}\n\n.TransactionsTable_searchPanel__1Elkb {\n  grid-column: 3;\n}\n\n.TransactionsTable_itemInfoWrapper__1WTVK {\n  display: flex;\n  flex-flow: column nowrap;\n  grid-gap: 0.25rem;\n  gap: 0.25rem;\n}", ""]);
// Exports
exports.locals = {
	"buttonsWrapper": "TransactionsTable_buttonsWrapper__1jGvX",
	"searchWrapper": "TransactionsTable_searchWrapper__3csiw",
	"searchPanel": "TransactionsTable_searchPanel__1Elkb",
	"itemInfoWrapper": "TransactionsTable_itemInfoWrapper__1WTVK"
};
module.exports = exports;
