import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import * as AT from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import api from '../../api/members';
import { setIsLoading } from './actions';
import { showErrorMessage } from '../ui/operations';
import { normalize, formatCase } from '../../utils/store';
import {
  MEMBERS_INITIAL_LIMIT,
  MEMBERS_INITIAL_SEARCH_TERM,
  MEMBERS_PAGE_LIMIT,
} from '../../utils/members';

function* onGetCommunityMembers({ payload = {} }) {
  try {
    const {
      searchTerm = MEMBERS_INITIAL_SEARCH_TERM,
      isLoadMore = true,
      nextPage = 0,
    } = payload;

    const currentMembersCount = yield select(selectors.getMembersOffset);
    const isNeedToFetchData = currentMembersCount <= (nextPage * MEMBERS_PAGE_LIMIT);

    const isExistMore = yield select(selectors.getIsExistMore);

    if (isLoadMore && (!isExistMore || !isNeedToFetchData)) {
      return;
    }

    yield put(actions.setIsLoading(true));

    const offset = isLoadMore
      ? currentMembersCount
      : 0;

    const limit = offset === 0
      ? MEMBERS_INITIAL_LIMIT
      : MEMBERS_PAGE_LIMIT;

    const members = yield call(api.getCommunityMembers, {
      offset,
      limit,
      searchTerm,
    });
    const hasMore = members.length === limit;

    const normalizedMembers = normalize(members);

    yield put(actions.getCommunityMembersSuccess({
      ...normalizedMembers,
      isLoadMore,
      isExistMore: hasMore,
    }));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setIsLoading(false));
  }
}

function* onGetMemberSuspensions({ payload = {} }) {
  try {
    const {
      searchTerm = MEMBERS_INITIAL_SEARCH_TERM,
      isLoadMore = true,
      nextPage = 0,
      isOnlyActive = false,
    } = payload;

    const currentSuspensions = yield select(selectors.getMemberSuspensions);
    const isNeedToFetchData = currentSuspensions.length <= (nextPage * MEMBERS_PAGE_LIMIT);
    const isExistMore = yield select(selectors.getIsExistMore);

    if (isLoadMore && (!isExistMore || !isNeedToFetchData)) {
      return;
    }

    yield put(actions.setIsLoading(true));

    const offset = isLoadMore
      ? currentSuspensions.length
      : 0;

    const limit = offset === 0
      ? MEMBERS_INITIAL_LIMIT
      : MEMBERS_PAGE_LIMIT;

    const suspensions = yield call(api.getMemberSuspensions, {
      offset,
      limit,
      searchTerm,
      isOnlyActive,
    });

    const hasMore = suspensions.length === limit;

    const normalizedSuspensions = formatCase.toCamelCase(suspensions);

    yield put(actions.getCommunitySuspensionsSuccess({
      entities: normalizedSuspensions,
      isLoadMore,
      isExistMore: hasMore,
    }));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setIsLoading(false));
  }
}

function* onGetMemberSuspicions({ payload = {} }) {
  try {
    const {
      searchTerm = MEMBERS_INITIAL_SEARCH_TERM,
      isLoadMore = true,
      nextPage = 0,
      isOnlyActive = false,
    } = payload;

    const currentSuspicions = yield select(selectors.getMemberSuspicions);
    const isNeedToFetchData = currentSuspicions.length <= (nextPage * MEMBERS_PAGE_LIMIT);
    const isExistMore = yield select(selectors.getIsExistMore);

    if (isLoadMore && (!isExistMore || !isNeedToFetchData)) {
      return;
    }

    yield put(actions.setIsLoading(true));

    const offset = isLoadMore
      ? currentSuspicions.length
      : 0;

    const limit = offset === 0
      ? MEMBERS_INITIAL_LIMIT
      : MEMBERS_PAGE_LIMIT;

    const suspicions = yield call(api.getMemberSuspicions, {
      offset,
      limit,
      searchTerm,
      isOnlyActive,
    });

    const hasMore = suspicions.length === limit;

    const normalizedSuspicions = formatCase.toCamelCase(suspicions);

    yield put(actions.getCommunitySuspicionsSuccess({
      entities: normalizedSuspicions,
      isLoadMore,
      isExistMore: hasMore,
    }));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setIsLoading(false));
  }
}

function* onGetCommunityMemberInfo({ payload: userId }) {
  const memberInfo = yield call(api.getCommunityMemberInfo, { userId });
  yield put(actions.getCommunityMemberInfoSuccess(formatCase.toCamelCase(memberInfo)));
}

function* onSuspendMember({ payload }) {
  yield call(api.suspendMember, formatCase.toSnakeCase({
    userId: payload.id,
    adminId: payload.adminId,
    reason: payload.reason,
  }));

  yield put(actions.getCommunityMemberInfo(payload.id));
}

function* onUnsuspendMember({ payload }) {
  yield call(api.unsuspendMember, formatCase.toSnakeCase({
    userId: payload.id,
    adminId: payload.adminId,
    rehabNote: payload.reason,
  }));
}

function* onMarkMemberAsSuspicious({ payload }) {
  yield call(api.markMemberAsSuspicious, formatCase.toSnakeCase({
    userId: payload.id,
    reason: payload.reason,
  }));

  yield put(actions.getCommunityMemberInfo(payload.id));
}

function* onUnmarkMemberAsSuspicious({ payload }) {
  yield call(api.unmarkMemberAsSuspicious, formatCase.toSnakeCase({ userId: payload.id }));
}

function* onToggleMemberShipperStatus({ payload }) {
  try {
    yield call(api.toggleUserShipperStatus, payload);
  } catch (error) {
    yield* showErrorMessage(error);
  }
}

function* onCancelItemArrival({ payload }) {
  try {
    yield call(api.cancelItemArrival, payload.offerId);
    yield put(showNotification('message.success.action.cancelArrival'));
  } catch (error) {
    yield* showErrorMessage(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.GET_COMMUNITY_MEMBERS, onGetCommunityMembers),
    takeEvery(AT.GET_COMMUNITY_MEMBER_INFO, onGetCommunityMemberInfo),
    takeEvery(AT.SUSPEND_MEMBER, onSuspendMember),
    takeEvery(AT.UNSUSPEND_MEMBER, onUnsuspendMember),
    takeEvery(AT.MARK_MEMBER_AS_SUSPICIOUS, onMarkMemberAsSuspicious),
    takeEvery(AT.UNMARK_MEMBER_AS_SUSPICIOUS, onUnmarkMemberAsSuspicious),
    takeEvery(AT.GET_COMMUNITY_SUSPENSIONS, onGetMemberSuspensions),
    takeEvery(AT.GET_COMMUNITY_SUSPICIONS, onGetMemberSuspicions),
    takeEvery(AT.TOGGLE_MEMBER_SHIPPER_STATUS, onToggleMemberShipperStatus),
    takeEvery(AT.CANCEL_ITEM_ARRIVAL, onCancelItemArrival),
  ]);
}
