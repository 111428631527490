// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Search_input__3iTQH {\n  min-height: 50px;\n}[dir] .Search_input__3iTQH {\n  border-radius: 5px !important;\n  padding: 0.75rem 1.25rem;\n}", ""]);
// Exports
exports.locals = {
	"input": "Search_input__3iTQH"
};
module.exports = exports;
