// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.CommunityInfo_communityInfoWidget__1yhGA {\n  min-height: 50vh;\n}\n\n.CommunityInfo_communityInfoWrapper__VF9aN {\n  grid-template: auto/70%;\n  grid-gap: 4rem;\n}\n\n@media (max-width: 991.98px) {\n  .CommunityInfo_communityInfoWrapper__VF9aN {\n    grid-template-columns: 1fr;\n  }\n}", ""]);
// Exports
exports.locals = {
	"communityInfoWidget": "CommunityInfo_communityInfoWidget__1yhGA",
	"communityInfoWrapper": "CommunityInfo_communityInfoWrapper__VF9aN"
};
module.exports = exports;
