// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.Menu_nav__2KYiZ {\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n[dir=ltr] .Menu_nav__2KYiZ {\n  padding: 30px 0 10px 0.5rem;\n}\n\n[dir=rtl] .Menu_nav__2KYiZ {\n  padding: 30px 0.5rem 10px 0;\n}", ""]);
// Exports
exports.locals = {
	"nav": "Menu_nav__2KYiZ"
};
module.exports = exports;
