import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { connect, useDispatch, useSelector } from 'react-redux';
import cls from 'classnames';
import * as subcommunitiesSelectors from '../../store/subcommunities/selectors';
import PageContent from '../../components/PageContent';
import Widget from '../../components/Widget';
import PageNavigation from '../../components/PageNavigation';
import styles from './Subcommunities.module.scss';
import Button, { buttonProps } from '../../components/Button';
import ModalService from '../../modals/ModalService';
import { modalIds } from '../../modals/const';
import Text, { textProps } from '../../components/Text';
import Switcher from '../../components/Switcher/Switcher';
import SubcommunitySelect from '../../components/SubcommunitySelect';
import { setSubcommunityId, updateSubcommunity } from '../../store/subcommunities/actions';
import { getSelectedSubcommunityId } from '../../store/subcommunities/selectors';
import ImageUploader from '../../components/ImageUploader';
import SubcommunityJoinCode from './Fields/SubcommunityJoinCode';
import SubcommunityName from './Fields/SubcommunityName';
import imageUploaderApi from '../../domains/ImageUploader/imageUploader.api';

const Subcommunities = ({ selectedSubcommunityId }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const selectedSubcommunity = useSelector(subcommunitiesSelectors.getSubcommunityById);

  const [backgroundImage, setBackgroundImage] = useState('');

  const onOpenGroupChangeOrderModal = () => {
    ModalService.open(modalIds.SUBCOMMUNITIES_MODAL);
  };

  const onChangeIsPrivate = (event) => {
    const isChecked = event.target.checked;

    dispatch(updateSubcommunity({ is_private: isChecked }));
  };

  const onChange = (option) => {
    setSubcommunityId(option?.id);
  };

  useEffect(() => {
    if (selectedSubcommunity) {
      setBackgroundImage(selectedSubcommunity?.backgroundImage);
    }
  }, [selectedSubcommunityId, selectedSubcommunity]);

  async function onChangeImage(image) {
    const formData = new FormData();

    formData.append('image', image);
    const link = await imageUploaderApi.uploadImage(formData);

    setBackgroundImage(link);

    await dispatch(updateSubcommunity({ background_image: link }));
  }

  return (
    <PageContent title={translate('pages.subcommunities.title.page')}>
      <Widget className={styles.groupInfoWidget}>
        <PageNavigation className="mb-5">
          <SubcommunitySelect onChange={onChange} />

          <div className={styles.createButton}>
            <Button theme={buttonProps.themes.link} onClick={onOpenGroupChangeOrderModal}>
              {translate('pages.subcommunities.buttons.create')}
            </Button>
          </div>
        </PageNavigation>

        <div className={cls('d-grid', 'mb-5', styles.groupInfoGrid)}>
          <ImageUploader defaultValue={backgroundImage} onChange={onChangeImage} />

          <SubcommunityName initialValue={selectedSubcommunity?.name} />

          <Text
            color={textProps.colors.success}
            size={textProps.sizes.small}
            weight={textProps.weights.bold}
          >
            {translate('pages.subcommunities.inputs.isPrivate.label')}
          </Text>

          <Switcher
            id="subCommunityEdit"
            checked={selectedSubcommunity?.isPrivate}
            onChange={onChangeIsPrivate}
          />

          <SubcommunityJoinCode initialValue={selectedSubcommunity?.joinCode} />
        </div>
      </Widget>
    </PageContent>
  );
};

Subcommunities.propTypes = {
  selectedGroup: PropTypes.shape({
    groupName: PropTypes.shape({
      en: PropTypes.string,
      he: PropTypes.string,
    }).isRequired,
    about: PropTypes.string.isRequired,
    backgroundImage: PropTypes.string,
  }),
};

Subcommunities.defaultProps = {
  selectedGroup: null,
};

export default connect((state) => {
  return {
    selectedSubcommunityId: getSelectedSubcommunityId(state),
  };
}, {})(Subcommunities);
