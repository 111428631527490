// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.AppBar_root__2zoeh {\n  position: fixed !important;\n  z-index: 100;\n  width: 100%;\n  flex-direction: row;\n  flex-wrap: nowrap !important;\n  height: 60px;\n  transition: background-color 0.2s ease, margin 0.2s ease;\n}\n\n[dir] .AppBar_root__2zoeh {\n  background: #fff;\n  box-shadow: 0 23px 20px -20px rgba(115, 162, 208, 0.1), 0 0 15px rgba(115, 162, 208, 0.06);\n}\n.AppBar_root__2zoeh a {\n  color: #495057;\n}\n@media (max-width: 767.98px) {\n  [dir] .AppBar_root__2zoeh {\n    padding: 0 !important;\n  }\n}", ""]);
// Exports
exports.locals = {
	"root": "AppBar_root__2zoeh"
};
module.exports = exports;
