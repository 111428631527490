import { createSelector } from 'reselect';
import { getParamWithoutStateInSelector } from '../../utils/store';

export const getSubcommunitiesEntities = (state) => {
  return state.subcommunities.subcommunitiesEntities;
};

export const getSubcommunitiesIds = (state) => {
  return state.subcommunities.subcommunitiesIds;
};

export const getSelectedSubcommunityId = (state) => {
  return state.subcommunities.selectedSubcommunityId;
};

export const getSubcommunities = createSelector(
  [getSubcommunitiesIds, getSubcommunitiesEntities],
  (ids, entities) => {
    return ids.map((id) => {
      const entity = entities[id];

      return {
        ...entity,
      };
    });
  },
);

export const getSubcommunitiesForSelect = createSelector([getSubcommunities], (data) => {
  return data.map(({ id, name, backgroundImage }) => {
    return {
      value: id,
      label: name,
      icon: backgroundImage,
    };
  });
});

export const getSubcommunityById = createSelector(
  [getSubcommunitiesEntities, getSelectedSubcommunityId],
  (entities, id) => {
    return entities[id];
  },
);

export const getSelectedSubcommunity = createSelector(
  [getSubcommunityById, getSelectedSubcommunityId],
  (data) => {
    return data;
  },
);

export const getSelectedSubcommunityForSelect = createSelector(
  [getSelectedSubcommunity],
  (data) => {
    return {
      value: data?.id,
      label: data?.name,
      icon: data?.backgroundImage,
    };
  },
);
