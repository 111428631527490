const prefix = 'members/';

export const GET_COMMUNITY_MEMBERS = `${prefix}GET_COMMUNITY_MEMBERS`;
export const GET_COMMUNITY_MEMBERS_SUCCESS = `${prefix}GET_COMMUNITY_MEMBERS_SUCCESS`;

export const GET_COMMUNITY_SUSPENSIONS = `${prefix}GET_COMMUNITY_SUSPENSIONS`;
export const GET_COMMUNITY_SUSPENSIONS_SUCCESS = `${prefix}GET_COMMUNITY_SUSPENSIONS_SUCCESS`;

export const GET_COMMUNITY_SUSPICIONS = `${prefix}GET_COMMUNITY_SUSPICIONS`;
export const GET_COMMUNITY_SUSPICIONS_SUCCESS = `${prefix}GET_COMMUNITY_SUSPICIONS_SUCCESS`;

export const SET_IS_LOADING = `${prefix}SET_IS_LOADING`;

export const GET_COMMUNITY_MEMBER_INFO = `${prefix}GET_COMMUNITY_MEMBER_INFO`;
export const GET_COMMUNITY_MEMBER_INFO_SUCCESS = `${prefix}GET_COMMUNITY_MEMBER_INFO_SUCCESS`;

export const SUSPEND_MEMBER = `${prefix}SUSPEND_MEMBER`;
export const UNSUSPEND_MEMBER = `${prefix}UNSUSPEND_MEMBER`;
export const MARK_MEMBER_AS_SUSPICIOUS = `${prefix}MARK_MEMBER_AS_SUSPICIOUS`;
export const UNMARK_MEMBER_AS_SUSPICIOUS = `${prefix}UNMARK_MEMBER_AS_SUSPICIOUS`;

export const SET_MEMBER_FILTER_TYPE = `${prefix}SET_MEMBER_FILTER_TYPE`;

export const TOGGLE_MEMBER_SHIPPER_STATUS = `${prefix}TOGGLE_MEMBER_SHIPPER_STATUS`;

export const CANCEL_ITEM_ARRIVAL = `${prefix}CANCEL_ITEM_ARRIVAL`;
