// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n[dir] .Layout_layout__3H_tR {\n  background-color: #F5F6F7;\n}\n[dir] .Layout_layout__3H_tR > div {\n  margin-top: 0 !important;\n}\n[dir] .Layout_layout__3H_tR > div > main {\n  margin-top: 60px;\n}", ""]);
// Exports
exports.locals = {
	"layout": "Layout_layout__3H_tR"
};
module.exports = exports;
