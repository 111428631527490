// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BackButton_wrapper__2vgpx {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n[dir=ltr] .BackButton_buttonIcon__XndqN {\n  margin-right: 4px;\n}\n\n[dir=rtl] .BackButton_buttonIcon__XndqN {\n  margin-left: 4px;\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "BackButton_wrapper__2vgpx",
	"buttonIcon": "BackButton_buttonIcon__XndqN"
};
module.exports = exports;
