export const REPORT_TABS = {
  USER: 'user',
  ITEM: 'item',
  REPORTERS: 'reporters',
  REPORTED_USERS: 'reportedUsers',
  TOP_REPORTS: 'topReports',
};

export const REPORTS_INITIAL_LIMIT = 100;
export const REPORTS_LIMIT = 20;
export const REPORTS_REQUEST_DELAY = 300;

export const REPORT_REASONS_MAP_BY_ID = {
  1: 'Offensive content',
  2: 'Profanity',
  3: 'Inappropriate item',
  4: 'Something else',
  5: 'Price',
};

export const initialSearchTerm = {
  reportedUserName: null,
  reporterUserName: null,
  itemTitle: null,
};
