// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.UserSelect_select__qei2W {\n  max-width: 300px;\n}\n\n.UserSelect_option__1VZhI {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-start;\n  align-items: center;\n  grid-gap: 0.5rem;\n  gap: 0.5rem;\n  color: inherit;\n  font-size: inherit;\n  font-family: Montserrat, sans-serif;\n  width: 100%;\n  -webkit-user-select: none;\n          user-select: none;\n  box-sizing: border-box;\n}\n\n[dir] .UserSelect_option__1VZhI {\n  background-color: transparent;\n  padding: 0.5rem 1rem;\n}\n[dir] .UserSelect_option__1VZhI.UserSelect_isFocused__1RPzG {\n  background-color: #DEEBFF;\n}\n\n.UserSelect_optionImage__2EE3L {\n  width: 25px;\n  height: 25px;\n  object-fit: cover;\n}\n\n[dir] .UserSelect_optionImage__2EE3L {\n  border-radius: 50%;\n}\n\n[dir=ltr] .UserSelect_optionImage__2EE3L {\n  margin-right: 14px;\n}\n\n[dir=rtl] .UserSelect_optionImage__2EE3L {\n  margin-left: 14px;\n}", ""]);
// Exports
exports.locals = {
	"select": "UserSelect_select__qei2W",
	"option": "UserSelect_option__1VZhI",
	"isFocused": "UserSelect_isFocused__1RPzG",
	"optionImage": "UserSelect_optionImage__2EE3L"
};
module.exports = exports;
