// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.MembersTable_nameWrapper__3Dinl {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-start;\n  align-items: center;\n  grid-gap: 0.5rem;\n  gap: 0.5rem;\n  position: relative;\n}\n\n.MembersTable_avatar__3Ze6i {\n  width: 25px;\n  height: 25px;\n  object-fit: cover;\n}\n\n[dir] .MembersTable_avatar__3Ze6i {\n  border-radius: 50%;\n}\n\n[dir=ltr] .MembersTable_avatar__3Ze6i {\n  margin-right: 14px;\n}\n\n[dir=rtl] .MembersTable_avatar__3Ze6i {\n  margin-left: 14px;\n}\n\n.MembersTable_shipperBadge__2vIEn {\n  position: absolute;\n  top: 0;\n}\n\n[dir] .MembersTable_shipperBadge__2vIEn {\n  padding: 4px;\n}\n\n[dir=ltr] .MembersTable_shipperBadge__2vIEn {\n  right: 0;\n}\n\n[dir=rtl] .MembersTable_shipperBadge__2vIEn {\n  left: 0;\n}\n\n.MembersTable_searchWrapper__tdOOU {\n  display: grid;\n  grid-template: 1fr/repeat(3, 1fr);\n  grid-gap: 1rem;\n}\n\n.MembersTable_searchPanel__2YyfQ {\n  grid-column: 3;\n}\n\n.MembersTable_activeCheckBox__3q8GG {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  grid-gap: 1rem;\n  gap: 1rem;\n  text-transform: capitalize;\n}\n\n@media (max-width: 576px) {\n  .MembersTable_searchWrapper__tdOOU {\n    grid-template-columns: 1fr;\n  }\n  .MembersTable_searchPanel__2YyfQ {\n    grid-column: 1;\n  }\n}", ""]);
// Exports
exports.locals = {
	"nameWrapper": "MembersTable_nameWrapper__3Dinl",
	"avatar": "MembersTable_avatar__3Ze6i",
	"shipperBadge": "MembersTable_shipperBadge__2vIEn",
	"searchWrapper": "MembersTable_searchWrapper__tdOOU",
	"searchPanel": "MembersTable_searchPanel__2YyfQ",
	"activeCheckBox": "MembersTable_activeCheckBox__3q8GG"
};
module.exports = exports;
