import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { connect } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  FormGroup,
} from 'reactstrap';
import {
  getSubcommunities as getSubcommunitiesAction,
  setSubcommunityId,
} from '../../store/subcommunities/actions';
import styles from './Subcommunity.module.scss';
import { getSelectedCommunityId } from '../../store/community/selectors';
import {
  getSelectedSubcommunityForSelect,
  getSubcommunitiesForSelect,
} from '../../store/subcommunities/selectors';

const SubcommunitySelect = memo(
  ({
    options,
    onChange,
    id,
    className,
    communityId,
    setSubcommunityId,
    getSubcommunitiesAction,
  }) => {
    useEffect(() => {
      if (!isNil(communityId)) {
        getSubcommunitiesAction();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communityId]);

    const [selectedOption, setSelectedOption] = useState(null);

    const onSelect = (option) => {
      setSelectedOption(option);
      onChange(option);

      setSubcommunityId(option?.value);
    };

    const isSelectDisabled = options.length === 0;

    useEffect(() => {
      if (!selectedOption && !isEmpty(options)) {
        console.log(options, selectedOption);

        onSelect(options[0]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, selectedOption]);

    return (
      <FormGroup className={className}>
        <UncontrolledButtonDropdown id={id} className={styles.selectWrapper}>
          <DropdownToggle
            className={cls(styles.selectPlaceholder, 'rounded')}
            disabled={isSelectDisabled}
            color="default"
            size="lg"
          >
            {selectedOption?.label}
            <b className={cls('fa', 'fa-angle-down', styles.caret)} />
          </DropdownToggle>
          <DropdownMenu className={styles.selectMenu}>
            {options.map((option) => {
              const onClick = () => {
                onSelect(option);
              };

              return (
                <DropdownItem onClick={onClick} key={option.value}>
                  <img
                    width={25}
                    height={25}
                    src={option.icon}
                    alt=""
                    className={cls('mr-3', {
                      [styles.emptyImage]: isNil(option.icon),
                    })}
                  />
                  {option.label}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </FormGroup>
    );
  },
);

SubcommunitySelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      icon: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  communityId: PropTypes.number,
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  getSubcommunitiesAction: PropTypes.func.isRequired,
  setSubcommunityId: PropTypes.func.isRequired,
};

SubcommunitySelect.defaultProps = {
  communityId: null,
  label: null,
  id: null,
  className: null,
};

export default connect(
  (state) => {
    return {
      options: getSubcommunitiesForSelect(state),
      communityId: getSelectedCommunityId(state),
      selectedSubcommunity: getSelectedSubcommunityForSelect(state),
    };
  },
  {
    getSubcommunitiesAction: getSubcommunitiesAction,
    setSubcommunityId,
  },
)(SubcommunitySelect);
