import { put } from 'redux-saga/effects';
import { showNotification } from 'ra-core';
import { hasPath } from 'ramda';

export function* showErrorMessage(error) {
  // eslint-disable-next-line no-console
  console.warn(error);
  const errorMessage = hasPath(['response', 'status'], error)
    ? `${error.response.status} — ${error.response.data}`
    : error;

  yield put(showNotification(errorMessage, 'error'));
}
