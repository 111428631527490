import { createAction } from 'redux-actions';
import * as AT from './types';

export const reportsStart = createAction(AT.REPORTS_START);
export const reportsSuccess = createAction(AT.REPORTS_SUCCESS);

export const reportersStart = createAction(AT.REPORTERS_START);
export const reportersSuccess = createAction(AT.REPORTERS_SUCCESS);

export const reportedUsersStart = createAction(AT.REPORTED_USERS_START);
export const reportedUsersSuccess = createAction(AT.REPORTED_USERS_SUCCESS);

export const topReportsStart = createAction(AT.TOP_REPORTS_START);
export const topReportsSuccess = createAction(AT.TOP_REPORTS_SUCCESS);

export const setReportsTab = createAction(AT.SET_REPORTS_TAB);

export const setIsLoading = createAction(AT.SET_IS_LOADING);
