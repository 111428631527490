import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { Button as UIButton } from 'reactstrap';

const buttonThemes = {
  primary: 'primary',
  secondary: 'secondary',
  info: 'info',
  gray: 'gray',
  transparent: 'transparent',
  inverse: 'inverse',
  link: 'link',
  success: 'success',
  danger: 'danger',
  warning: 'warning',
  null: null,
};

const buttonColors = {
  light: 'light',
  dark: 'dark',
  secondary: 'secondary',
  null: null,
};

const buttonWeights = {
  bold: 'bold',
};

const buttonSizes = {
  large: 'lg',
  null: null,
};

export const buttonProps = {
  themes: buttonThemes,
  colors: buttonColors,
  weights: buttonWeights,
  sizes: buttonSizes,
};

const Button = ({
  children,
  onClick,
  disabled,
  type,
  classNames,
  theme,
  color,
  weight,
  size,
  marginTop,
  outline,
  ...rest
}) => {
  return (
    <UIButton
      className={cls(classNames, 'btn-rounded', 'text-nowrap', {
        [`text-${color}`]: color,
        [`font-weight-${weight}`]: weight,
        'mt-3 mt-md-0': marginTop,
      })}
      onClick={onClick}
      disabled={disabled}
      color={theme}
      type={type}
      size={size}
      outline={outline}
      {...rest}
    >
      {children}
    </UIButton>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  disabled: PropTypes.bool,
  marginTop: PropTypes.bool,
  outline: PropTypes.bool,
  onClick: PropTypes.func,
  classNames: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(buttonThemes)),
  color: PropTypes.oneOf(Object.values(buttonColors)),
  weight: PropTypes.oneOf(Object.values(buttonWeights)),
  size: PropTypes.oneOf(Object.values(buttonSizes)),
};

Button.defaultProps = {
  disabled: false,
  marginTop: true,
  outline: false,
  onClick: null,
  classNames: null,
  type: 'button',
  theme: buttonThemes.null,
  color: buttonColors.null,
  weight: buttonWeights.null,
  size: buttonSizes.large,
};

export default Button;
