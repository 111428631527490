import { all, takeEvery, select, call, put } from 'redux-saga/effects';
import { compose, isEmpty } from 'ramda';
import * as AT from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import api from '../../api/reports';
import { getSelectedCommunityId } from '../community/selectors';
import {
  REPORTS_INITIAL_LIMIT,
  REPORTS_LIMIT,
  initialSearchTerm,
  REPORT_TABS,
} from '../../constants/reports';
import { combineDataWithNormalize, formatCase, normalize } from '../../utils/store';
import { updateReportIds } from '../../utils/reports';

function* onReportsStart({ payload }) {
  const {
    type,
    searchTerm = initialSearchTerm,
    isLoadMore = true,
    nextPage = 0,
  } = payload;

  const isExistMore = yield select(selectors.getIsReportsExistMoreByType, type);
  const isLoading = yield select(selectors.getIsReportsLoadingByType, type);
  const currentReportsCount = yield select(selectors.getReportsOffsetByType, type);
  const isNeedToFetchData = currentReportsCount <= (nextPage * REPORTS_LIMIT);

  if (isLoading || (isLoadMore && (!isExistMore || !isNeedToFetchData))) {
    return;
  }

  yield put(actions.setIsLoading({ isLoading: true, type }));

  const communityId = yield select(getSelectedCommunityId);
  const offset = isLoadMore
    ? currentReportsCount
    : 0;

  const limit = offset === 0
    ? REPORTS_INITIAL_LIMIT
    : REPORTS_LIMIT;

  const reports = yield call(api.getReports, {
    communityId,
    type,
    offset,
    limit,
    searchTerm: formatCase.toSnakeCase(searchTerm),
  });
  const hasMore = reports.length === limit;

  const normalizedReports = normalize(reports);

  yield put(actions.reportsSuccess({
    ...normalizedReports,
    isExistMore: hasMore,
    type,
    isLoadMore,
  }));

  yield put(actions.setIsLoading({ isLoading: false, type }));
}

function* onReportersStart() {
  const communityId = yield select(getSelectedCommunityId);

  const reporters = yield call(api.getReporters, communityId);

  const normalizedReporters = compose(
    combineDataWithNormalize,
    updateReportIds,
    formatCase.toCamelCase,
  )(reporters);

  yield put(actions.reportersSuccess(normalizedReporters));
}

function* onReportedUsersStart() {
  const communityId = yield select(getSelectedCommunityId);

  const reportedUsers = yield call(api.getReportedUsers, communityId);

  const normalizedReportedUsers = compose(
    combineDataWithNormalize,
    updateReportIds,
    formatCase.toCamelCase,
  )(reportedUsers);

  yield put(actions.reportedUsersSuccess(normalizedReportedUsers));
}

function* onTopReportsStart() {
  const communityId = yield select(getSelectedCommunityId);

  const topReports = yield call(api.getTopReports, communityId);

  yield put(actions.topReportsSuccess(formatCase.toCamelCase(topReports)));
}

function* onSetReportsTab({ payload: type }) {
  switch (type) {
    case REPORT_TABS.TOP_REPORTS: {
      const topReportsData = yield select(selectors.getTopReports);

      if (isEmpty(topReportsData)) {
        yield put(actions.topReportsStart());
      }

      break;
    }

    case REPORT_TABS.REPORTERS: {
      yield put(actions.reportersStart());
      break;
    }

    case REPORT_TABS.REPORTED_USERS: {
      yield put(actions.reportedUsersStart());
      break;
    }

    case REPORT_TABS.USER:
    case REPORT_TABS.ITEM: {
      yield put(actions.reportsStart({ type }));
      break;
    }

    default:
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.REPORTS_START, onReportsStart),
    takeEvery(AT.REPORTERS_START, onReportersStart),
    takeEvery(AT.REPORTED_USERS_START, onReportedUsersStart),
    takeEvery(AT.TOP_REPORTS_START, onTopReportsStart),
    takeEvery(AT.SET_REPORTS_TAB, onSetReportsTab),
  ]);
}
