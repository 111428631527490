// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.TabsContent_tabsContent__34cW3 {\n  min-height: 40vh;\n}\n\n[dir] .TabsContent_tabsContent__34cW3 {\n  box-shadow: 0 23px 20px -20px rgba(115, 162, 208, 0.1), 0 0 15px rgba(115, 162, 208, 0.06);\n}", ""]);
// Exports
exports.locals = {
	"tabsContent": "TabsContent_tabsContent__34cW3"
};
module.exports = exports;
