import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { isEmpty } from 'ramda';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import { updateCommunityInfo as updateCommunityInfoAction } from '../../store/community/actions';
import { validateField } from '../../store/validation/actions';
import { COMMUNITY_INFO_FIELD_IDS } from '../../constants/validation';
import { getCommunityInfo } from '../../store/community/selectors';
import { getValidationErrors } from '../../store/validation/selectors';
import PageContent from '../../components/PageContent';
import Widget from '../../components/Widget';
import Separator from '../../components/Separator';
import CommunityNameField from './Fields/CommunityName';
import CommunityAboutField from './Fields/CommunityAbout';
import CommunityIconField from './Fields/CommunityIcon';
import TokenNameField from './Fields/TokenName';
import WalletImageField from './Fields/WalletImage';
import styles from './CommunityInfo.module.scss';

const CommunityInfo = ({
  communityInfo,
  communityInfoValidationErrors,
  updateCommunityInfo,
  validateCommunityInfoField,
}) => {
  const translate = useTranslate();

  return (
    <PageContent
      title={translate('pages.communityInfo.title.page')}
    >
      <Widget className={styles.communityInfoWidget}>
        {!isEmpty(communityInfo) && (
          <>
            <div className={cls('d-grid', styles.communityInfoWrapper)}>
              <CommunityIconField
                initialValue={communityInfo.communityIcon}
                updateCommunityInfo={updateCommunityInfo}
              />

              <CommunityNameField
                initialValue={communityInfo.name}
                validationError={communityInfoValidationErrors.name}
                id={COMMUNITY_INFO_FIELD_IDS.COMMUNITY_NAME}
                validateName={validateCommunityInfoField}
                updateCommunityInfo={updateCommunityInfo}
              />

              <CommunityAboutField
                initialValue={communityInfo.about}
                updateCommunityInfo={updateCommunityInfo}
              />
            </div>

            <Separator />

            <div className={cls('d-grid', styles.communityInfoWrapper)}>
              <WalletImageField
                updateCommunityInfo={updateCommunityInfo}
                initialValue={communityInfo.walletBackgroundImage}
              />

              <TokenNameField
                initialValue={communityInfo.tokenName}
                validationError={communityInfoValidationErrors.token_name}
                id={COMMUNITY_INFO_FIELD_IDS.TOKEN_NAME}
                validateName={validateCommunityInfoField}
                updateCommunityInfo={updateCommunityInfo}
              />
            </div>
          </>
        )}
      </Widget>
    </PageContent>
  );
};

CommunityInfo.propTypes = {
  communityInfo: PropTypes.shape({
    name: PropTypes.string,
    about: PropTypes.string,
    communityIcon: PropTypes.string,
    walletBackgroundImage: PropTypes.string,
    tokenName: PropTypes.string,
  }).isRequired,
  communityInfoValidationErrors: PropTypes.shape({
    name: PropTypes.string,
    token_name: PropTypes.string,
  }).isRequired,

  updateCommunityInfo: PropTypes.func.isRequired,
  validateCommunityInfoField: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      communityInfo: getCommunityInfo(state),
      communityInfoValidationErrors: getValidationErrors(state),
    };
  }, {
    updateCommunityInfo: updateCommunityInfoAction,
    validateCommunityInfoField: validateField,
  },
)(CommunityInfo);
