const menuItems = [
  {
    index: 'dashboard',
    header: 'pages.dashboard.title.menu',
    path: '/',
  },
  // {
  //   index: 'communityInfo',
  //   header: 'pages.communityInfo.title.menu',
  //   path: '/communityInfo',
  // },
  {
    index: 'subcommunities',
    header: 'pages.subcommunities.title.menu',
    path: '/subcommunities',
  },
  {
    index: 'groupInfo',
    header: 'pages.groupInfo.title.menu',
    path: '/groupInfo',
  },
  // {
  //   index: 'rules',
  //   header: 'pages.rules.title.menu',
  //   path: '/rules',
  // },
  // {
  //   index: 'features',
  //   header: 'pages.features.title.menu',
  //   path: '/features',
  // },
  {
    index: 'categories',
    header: 'pages.categories.title.menu',
    path: '/categories',
  },
  {
    index: 'brands',
    header: 'pages.brands.title.menu',
    path: '/brands',
  },
  // {
  //   index: 'conditions',
  //   header: 'pages.conditions.title.menu',
  //   path: '/conditions',
  // },
  // {
  //   index: 'sizes',
  //   header: 'pages.sizes.title.menu',
  //   path: '/sizes',
  // },
  {
    index: 'members',
    header: 'pages.members.title.menu',
    path: '/members',
  },
  {
    index: 'items',
    header: 'pages.items.title.menu',
    path: '/items',
  },
  {
    index: 'talents',
    header: 'pages.talents.title.menu',
    path: '/talents',
  },
  // {
  //   index: 'wallets',
  //   header: 'pages.wallets.title.menu',
  //   path: '/wallets',
  // },
  {
    index: 'transactions',
    header: 'pages.transactions.title.menu',
    path: '/transactions',
  },
  {
    index: 'reports',
    header: 'pages.reports.title.menu',
    path: '/reports',
  },
  // {
  //   index: 'cities',
  //   header: 'pages.cities.title.menu',
  //   path: '/cities',
  // },
];

export default menuItems;
