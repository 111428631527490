// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ActivitiesTable_wrapper__1na1J {\n  overflow-x: scroll;\n  -webkit-overflow-scrolling: touch;\n}\n\n.ActivitiesTable_nameWrapper__3evjP {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-start;\n  align-items: center;\n  grid-gap: 0.5rem;\n  gap: 0.5rem;\n}\n\n.ActivitiesTable_itemInfoWrapper__EcUBx {\n  display: flex !important;\n  flex-flow: column nowrap;\n}\n\n.ActivitiesTable_avatar__2TExs {\n  width: 25px;\n  height: 25px;\n  object-fit: cover;\n}\n\n[dir] .ActivitiesTable_avatar__2TExs {\n  border-radius: 50%;\n}\n\n[dir=ltr] .ActivitiesTable_avatar__2TExs {\n  margin-right: 14px;\n}\n\n[dir=rtl] .ActivitiesTable_avatar__2TExs {\n  margin-left: 14px;\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "ActivitiesTable_wrapper__1na1J",
	"nameWrapper": "ActivitiesTable_nameWrapper__3evjP",
	"itemInfoWrapper": "ActivitiesTable_itemInfoWrapper__EcUBx",
	"avatar": "ActivitiesTable_avatar__2TExs"
};
module.exports = exports;
