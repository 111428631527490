// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.IconSelect_selectWrapper__1hvT7 {\n  display: flex !important;\n  max-width: 200px;\n  height: 40px;\n}\n\n[dir] .IconSelect_selectWrapper__1hvT7 {\n  background-color: #fff;\n}\n\n.IconSelect_selectMenu__3QCTm {\n  width: 100%;\n  max-width: 200px;\n  max-height: 230px;\n  overflow-y: scroll;\n}\n\n.IconSelect_selectPlaceholder__3ZVBx {\n  display: flex !important;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n  align-items: center;\n  color: #494A4C !important;\n  border: 1px solid #BFC0C2 !important;\n  font-weight: 600 !important;\n}\n\n.IconSelect_caret__3vy5T {\n  font-size: 2rem !important;\n}", ""]);
// Exports
exports.locals = {
	"selectWrapper": "IconSelect_selectWrapper__1hvT7",
	"selectMenu": "IconSelect_selectMenu__3QCTm",
	"selectPlaceholder": "IconSelect_selectPlaceholder__3ZVBx",
	"caret": "IconSelect_caret__3vy5T"
};
module.exports = exports;
