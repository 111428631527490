import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import qs from 'querystring';
import { useTranslate, useLocale } from 'react-admin';
import { Link } from 'react-router-dom';
import { formatDateWithLocale, getDistanceFromDateToNow } from '../../../../utils/dateFns';
import ModalService from '../../../../modals/ModalService';
import { modalIds } from '../../../../modals/const';
import { membersWhiteList, memberTypes } from '../../../../constants/members';
import Text, { textProps } from '../../../../components/Text';
import Badge, { badgeProps } from '../../../../components/Badge';
import Alert, { alertProps } from '../../../../components/Alert';
import Button, { buttonProps } from '../../../../components/Button';
import styles from './MemberCard.module.scss';

// eslint-disable-next-line react/prop-types
const MemberInfoItem = ({ title, subTitle, color }) => {
  return (
    <div className={styles.memberInfoItem}>
      <Text
        color={color}
        weight={textProps.weights.bold}
        size={textProps.sizes.xxxLarge}
      >
        {title}
      </Text>
      <Text
        color={color}
        weight={textProps.weights.semiBold}
        size={textProps.sizes.small}
        className="text-nowrap"
      >
        {subTitle}
      </Text>
    </div>
  );
};

const MemberCard = ({ member, currentUserInfo, toggleShipperStatus }) => {
  const translate = useTranslate();
  const locale = useLocale();
  const {
    id,
    name,
    nickname,
    profileImage,
    createdAtDate,
    lastAccessDate,
    isAdmin,
    isSuperAdmin,
    isSuspended,
    isShipper,
    suspensionReason,
    isMarkedAsSuspicious,
    referrerUser,
    referralUsers,
    markAsSuspiciousReason,
    suspensionDate,
    suspensionsCount,
    suspiciousCount,
    balanceAmount,
    holdBalance,
    phoneNumber,
  } = member;

  const isMemberSimpleAdmin = !isSuperAdmin && isAdmin;
  const isWhiteListedUser = membersWhiteList.indexOf(parseInt(id, 10)) !== -1;

  const isSuspendButtonDisabled = (
    // super admin cannot be suspended
    isSuperAdmin ||
    // only super admins can suspend another admins
    (isMemberSimpleAdmin && !currentUserInfo.isSuperAdmin) ||
    // user cannot suspend themselves
    parseInt(id, 10) === parseInt(currentUserInfo.id, 10) ||
    // White listed user cannot be suspended (ex. Shareitt support)
    isWhiteListedUser
  );

  const getButtonTooltip = () => {
    if (parseInt(id, 10) === parseInt(currentUserInfo.id, 10)) {
      return translate('pages.memberInfo.tooltips.sameUser');
    }

    if (isSuperAdmin) {
      return translate('pages.memberInfo.tooltips.superAdmin');
    }

    if (isMemberSimpleAdmin && !currentUserInfo.isSuperAdmin) {
      return translate('pages.memberInfo.tooltips.noAccess');
    }

    if (isWhiteListedUser) {
      return translate('pages.memberInfo.tooltips.whiteList');
    }

    return null;
  };

  const onSuspensionClick = () => {
    ModalService.open(modalIds.SUSPENSION_MODAL, { id, adminId: currentUserInfo.id, isSuspended });
  };

  const onSuspiciousClick = () => {
    ModalService.open(modalIds.SUSPICIOUS_MODAL, { id, isMarkedAsSuspicious });
  };

  const onToggleShipperClick = () => {
    toggleShipperStatus({ userId: id, isShipper: !isShipper });
  };

  const suspensionsLinkParams = qs.stringify({
    type: memberTypes.SUSPENDED,
    searchTerm: phoneNumber.replace('+', ''),
  });

  const suspicionsLinkParams = qs.stringify({
    type: memberTypes.SUSPICIOUS,
    searchTerm: phoneNumber.replace('+', ''),
  });

  const [firstReferral, ...otherReferrals] = referralUsers;

  const onOtherReferralUsersClick = () => {
    ModalService.open(modalIds.REFERRAL_USERS_MODAL, { users: referralUsers });
  };

  return (
    <div className={cls('d-flex', styles.wrapper)}>
      <div className={styles.avatarWrapper}>
        <img src={profileImage} alt={name} className={styles.avatarImage} />
        <div className={styles.labelList}>
          {(isAdmin || isSuperAdmin) && (
            <Badge
              title={
                isSuperAdmin
                  ? translate('general.label.superAdmin')
                  : translate('general.label.admin')
              }
              size={12}
              theme={isSuperAdmin 
                ? badgeProps.themes.info 
                : badgeProps.themes.primary}
            />
          )}
          {isShipper && (
            <Badge
              title={translate('general.label.shipper')}
              size={12}
              theme={badgeProps.themes.primary}
            />
          )}
        </div>
      </div>
      <div className="flex-grow-1">
        <div className={styles.memberNameWrapper}>
          <Text size={28} weight={textProps.weights.bold}>
            {name}
          </Text>
          <Text size={28} weight={textProps.weights.bold}>{`(#${id})`}</Text>
          {nickname && (
            <Text
              size={textProps.sizes.large}
              color={textProps.colors.secondary}
              weight={textProps.weights.semiBold}
            >
              {`@${nickname}`}
            </Text>
          )}
        </div>

        <Text
          color={textProps.colors.secondary}
          size={textProps.sizes.small}
          weight={textProps.weights.semiBold}
        >
          {translate('pages.memberInfo.phoneNumber', {
            phoneNumber,
          })}
        </Text>
        <Text color={textProps.colors.secondary} size={textProps.sizes.small}>
          {translate('pages.memberInfo.joinedAt', {
            date: formatDateWithLocale(createdAtDate, locale),
          })}
        </Text>
        <Text color={textProps.colors.secondary} size={textProps.sizes.small}>
          {translate('pages.memberInfo.lastSeen', {
            distance: getDistanceFromDateToNow(lastAccessDate, locale),
          })}
        </Text>

        {(referrerUser || referralUsers?.length) && (
          <div className={cls('mt-3')}>
            {referrerUser && (
              <Text color={textProps.colors.secondary} size={textProps.sizes.large}>
                {`${translate('pages.memberInfo.referredInfo')} `}
                <Link to={`/members/${referrerUser.id}`} className="d-inline-block">
                  <Text weight={textProps.weights.bold}>{referrerUser.name}</Text>
                </Link>
              </Text>
            )}

            {!!referralUsers?.length && (
              <Text
                color={textProps.colors.secondary}
                size={textProps.sizes.large}
                className={styles.referralInfoContainer}
              >
                {translate('pages.memberInfo.hasReferred')}
                <Link to={`/members/${firstReferral.id}`} className="d-inline-block">
                  <Text weight={textProps.weights.bold}>{firstReferral.name}</Text>
                </Link>

                {!!otherReferrals?.length && (
                  <Fragment>
                    <Button
                      onClick={onOtherReferralUsersClick}
                      classNames="d-inline-block p-0 border-0"
                      theme={buttonProps.themes.link}
                    >
                      <Text weight={textProps.weights.bold}>
                        {translate('pages.memberInfo.andOthers', { count: otherReferrals.length })}
                      </Text>
                    </Button>
                  </Fragment>
                )}
              </Text>
            )}
          </div>
        )}

        <div className={cls('d-grid', styles.memberInfoItemsList)}>
          <Link to={`/members?${suspensionsLinkParams}`}>
            <MemberInfoItem
              title={suspensionsCount}
              subTitle={translate('pages.memberInfo.suspensionsCount')}
              color={textProps.colors.danger}
            />
          </Link>

          <Link to={`/members?${suspicionsLinkParams}`}>
            <MemberInfoItem
              title={suspiciousCount}
              subTitle={translate('pages.memberInfo.suspiciousCount')}
              color={textProps.colors.warning}
            />
          </Link>

          <MemberInfoItem
            title={`${balanceAmount - holdBalance}`}
            subTitle={translate('pages.memberInfo.walletBalance')}
            color={textProps.colors.secondary}
          />
        </div>

        <div className={cls('d-flex', styles.memberAlertsWrapper)}>
          {isSuspended && (
            <Alert type={alertProps.types.danger}>
              <div>
                <Text color={textProps.colors.inherit} weight={textProps.weights.semiBold}>
                  {translate('pages.memberInfo.suspended', {
                    distance: getDistanceFromDateToNow(suspensionDate, locale),
                  })}
                </Text>
                {suspensionReason && (
                  <Text color={textProps.colors.inherit}>
                    {translate('pages.memberInfo.reason', { reason: suspensionReason })}
                  </Text>
                )}
              </div>
            </Alert>
          )}

          {isMarkedAsSuspicious && (
            <Alert type={alertProps.types.warning}>
              <div>
                <Text color={textProps.colors.inherit} weight={textProps.weights.semiBold}>
                  {translate('pages.memberInfo.markedAsSuspicious')}
                </Text>
                {markAsSuspiciousReason && (
                  <Text color={textProps.colors.inherit}>
                    {translate('pages.memberInfo.reason', { reason: markAsSuspiciousReason })}
                  </Text>
                )}
              </div>
            </Alert>
          )}
        </div>

        <div className={cls('d-flex', styles.buttonsGroup)}>
          <Button
            onClick={onSuspensionClick}
            disabled={isSuspendButtonDisabled}
            title={getButtonTooltip()}
            theme={buttonProps.themes.danger}
            weight={buttonProps.weights.bold}
            marginTop={false}
          >
            {isSuspended
              ? translate('pages.memberInfo.buttons.unsuspend')
              : translate('pages.memberInfo.buttons.suspend')}
          </Button>
          <Button
            onClick={onSuspiciousClick}
            disabled={isSuspendButtonDisabled}
            title={getButtonTooltip()}
            theme={buttonProps.themes.warning}
            weight={buttonProps.weights.bold}
            color={buttonProps.colors.light}
            marginTop={false}
          >
            {isMarkedAsSuspicious
              ? translate('pages.memberInfo.buttons.unmark')
              : translate('pages.memberInfo.buttons.mark')}
          </Button>
          <Button
            onClick={onToggleShipperClick}
            theme={buttonProps.themes.primary}
            weight={buttonProps.weights.bold}
            marginTop={false}
          >
            {isShipper
              ? translate('pages.memberInfo.buttons.deactivateShipper')
              : translate('pages.memberInfo.buttons.activateShipper')}
          </Button>
        </div>
      </div>
    </div>
  );
};

MemberCard.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    profileImage: PropTypes.string.isRequired,
    nickname: PropTypes.string,
    createdAtDate: PropTypes.string.isRequired,
    lastAccessDate: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isSuperAdmin: PropTypes.bool.isRequired,
    isSuspended: PropTypes.bool.isRequired,
    isShipper: PropTypes.bool.isRequired,
    suspensionReason: PropTypes.string,
    suspensionDate: PropTypes.string,
    suspensionsCount: PropTypes.number,
    suspiciousCount: PropTypes.number,
    balanceAmount: PropTypes.number,
    holdBalance: PropTypes.number,
    isMarkedAsSuspicious: PropTypes.bool.isRequired,
    referrerUser: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
    referralUsers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
      }),
    ),
    markAsSuspiciousReason: PropTypes.string,
  }).isRequired,
  currentUserInfo: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isSuperAdmin: PropTypes.bool,
  }).isRequired,
  toggleShipperStatus: PropTypes.func.isRequired,
};

export default MemberCard;
