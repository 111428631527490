import api from './Api';

const getCommunityMembers = ({ offset, limit, searchTerm }) => {
  const query = api.getQueryParamsFromObject({
    skip: offset,
    limit,
    search_term: searchTerm,
  });

  return api.get(`/admin/members?${query}`);
};

const getCommunityMemberInfo = ({ userId }) => {
  return api.get(`/admin/members/${userId}`);
};

const getMemberSuspensions = ({ offset, limit, searchTerm, isOnlyActive = false }) => {
  const query = api.getQueryParamsFromObject({
    skip: offset,
    limit,
    search_term: searchTerm,
    is_only_active: isOnlyActive,
  });

  return api.get(`/admin/suspendedAccounts?${query}`);
};

const suspendMember = (data) => {
  return api.post('/admin/suspendedAccounts', data);
};

const unsuspendMember = (data) => {
  return api.put('/admin/suspendedAccounts', data);
};

const getMemberSuspicions = ({ offset, limit, searchTerm, isOnlyActive = false }) => {
  const query = api.getQueryParamsFromObject({
    skip: offset,
    limit,
    search_term: searchTerm,
    is_only_active: isOnlyActive,
  });

  return api.get(`/admin/suspiciousUsers?${query}`);
};

const markMemberAsSuspicious = (data) => {
  return api.post('/admin/suspiciousUsers', data);
};

const unmarkMemberAsSuspicious = (data) => {
  return api.put('/admin/suspiciousUsers', data);
};

const toggleUserShipperStatus = ({ userId, isShipper }) => {
  return api.put('/admin/shippers', { user_id: userId, is_shipper: isShipper });
};

const cancelItemArrival = (offerId) => {
  return api.put(`/admin/offers/cancelArrival/${offerId}`);
};

export default {
  getCommunityMembers,
  getCommunityMemberInfo,
  getMemberSuspensions,
  getMemberSuspicions,
  suspendMember,
  unsuspendMember,
  markMemberAsSuspicious,
  unmarkMemberAsSuspicious,
  toggleUserShipperStatus,
  cancelItemArrival,
};
