// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Badge_badge__3px7Q {\n  vertical-align: center;\n}[dir] .Badge_badge__3px7Q {\n  padding: 5px 10px;\n  border-radius: 4px;\n  text-align: center;\n}", ""]);
// Exports
exports.locals = {
	"badge": "Badge_badge__3px7Q"
};
module.exports = exports;
