import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'ra-core';
import * as AT from './types';
import { deepMerge } from '../../utils/store';
import { memberTypes } from '../../constants/members';
import { offerTypes } from '../../constants/offers';

const initialState = {
  entities: {},
  ids: [],
  isExistMore: true,
  isLoading: false,
  filterType: memberTypes.ALL,
  suspensions: [],
  suspicions: [],
};

const reducer = handleActions({
  [AT.SET_IS_LOADING]: deepMerge(({ payload }) => {
    return {
      isLoading: payload,
    };
  }),
  [AT.GET_COMMUNITY_MEMBERS_SUCCESS]: deepMerge(({ payload }, state) => {
    return {
      entities: payload.entities,
      ids: payload.isLoadMore
        ? [...state.ids, ...payload.ids]
        : payload.ids,
      isExistMore: payload.isLoadMore
        ? payload.isExistMore
        : true,
    };
  }),
  [AT.GET_COMMUNITY_MEMBER_INFO_SUCCESS]: deepMerge(({ payload }) => {
    return {
      entities: {
        [payload.id]: payload,
      },
    };
  }),
  [AT.SUSPEND_MEMBER]: deepMerge(({ payload }) => {
    return {
      entities: {
        [payload.id]: {
          isSuspended: true,
          suspensionReason: payload.reason,
          suspensionDate: new Date().toISOString(),
        },
      },
    };
  }),
  [AT.UNSUSPEND_MEMBER]: deepMerge(({ payload }) => {
    return {
      entities: {
        [payload.id]: { isSuspended: false },
      },
    };
  }),
  [AT.MARK_MEMBER_AS_SUSPICIOUS]: deepMerge(({ payload }) => {
    return {
      entities: {
        [payload.id]: { isMarkedAsSuspicious: true, markAsSuspiciousReason: payload.reason },
      },
    };
  }),
  [AT.UNMARK_MEMBER_AS_SUSPICIOUS]: deepMerge(({ payload }) => {
    return {
      entities: {
        [payload.id]: { isMarkedAsSuspicious: false },
      },
    };
  }),
  [AT.SET_MEMBER_FILTER_TYPE]: deepMerge(({ payload }) => {
    return {
      filterType: payload,
      isExistMore: true,
    };
  }),
  [AT.GET_COMMUNITY_SUSPENSIONS_SUCCESS]: deepMerge(({ payload }, state) => {
    return {
      suspensions: payload.isLoadMore
        ? [...state.suspensions, ...payload.entities]
        : payload.entities,
      isExistMore: payload.isLoadMore
        ? payload.isExistMore
        : true,
    };
  }),
  [AT.GET_COMMUNITY_SUSPICIONS_SUCCESS]: deepMerge(({ payload }, state) => {
    return {
      suspicions: payload.isLoadMore
        ? [...state.suspicions, ...payload.entities]
        : payload.entities,
      isExistMore: payload.isLoadMore
        ? payload.isExistMore
        : true,
    };
  }),

  [AT.TOGGLE_MEMBER_SHIPPER_STATUS]: deepMerge(({ payload }) => ({
    entities: {
      [payload.userId]: { isShipper: payload.isShipper },
    },
  })),
  [AT.CANCEL_ITEM_ARRIVAL]: deepMerge(({ payload }, state) => ({
    entities:  {
      [payload.userId]: {
        activities: {
          [payload.activityType]:
            state.entities[payload.userId].activities[payload.activityType].map((offer) => {
              if (offer.id === payload.offerId) {
                return {
                  ...offer,
                  statusId: offerTypes.OFFER_CONFIRMED,
                  updatedAt: Date.now(),
                };
              }

              return offer;
            }),
        },
      },
    },
  })),

  [CLEAR_STATE]: () => {
    return initialState;
  },
}, initialState);

export default reducer;
