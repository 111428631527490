// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.TabsPanel_navTabs__ciol8 {\n  width: -moz-fit-content;\n  width: fit-content;\n  flex-wrap: nowrap !important;\n}\n@media (max-width: 575px) {\n  .TabsPanel_navTabs__ciol8 {\n    width: 100%;\n  }\n}\n\n[dir=ltr] .TabsPanel_navItem__2KduV {\n  border-left: none;\n}\n\n[dir=rtl] .TabsPanel_navItem__2KduV {\n  border-right: none;\n}\n@media (max-width: 575px) {\n  [dir] .TabsPanel_navItem__2KduV {\n    border-radius: 0 !important;\n  }\n  [dir=ltr] .TabsPanel_navItem__2KduV.TabsPanel_active__3vv_W ~ .TabsPanel_navItem__2KduV {\n    border-left: 10px solid #ffffff;\n  }\n  [dir=rtl] .TabsPanel_navItem__2KduV.TabsPanel_active__3vv_W ~ .TabsPanel_navItem__2KduV {\n    border-right: 10px solid #ffffff;\n  }\n}", ""]);
// Exports
exports.locals = {
	"navTabs": "TabsPanel_navTabs__ciol8",
	"navItem": "TabsPanel_navItem__2KduV",
	"active": "TabsPanel_active__3vv_W"
};
module.exports = exports;
