import { useTranslate } from 'ra-core';
import React, { useEffect, useState } from 'react';
import EditableTextarea from '../../../components/EditableTextarea';
import { updateSubcommunity } from '../../../store/subcommunities/actions';
import { useDispatch } from 'react-redux';

const SubcommunityName = ({ initialValue }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const [name, setName] = useState(initialValue);
  const isValueChanged = name !== initialValue;
  const isSaveButtonDisabled = !isValueChanged;

  useEffect(() => {
    setName(initialValue);
  }, [initialValue]);

  async function handleOnChange(value) {
    setName(value);
  }

  const onEditName = async () => {
    dispatch(updateSubcommunity({ name }));
  };

  return (
    <EditableTextarea
      value={initialValue}
      label={translate('pages.subcommunities.inputs.name.label')}
      maxLength={30}
      id="subcommunityName"
      showButtons
      onSave={onEditName}
      onInputChange={handleOnChange}
      isSaveButtonDisabled={isSaveButtonDisabled}
    />
  );
};

export default SubcommunityName;
