const ENV = 'production';
let baseApiUrl = '';

if (ENV === 'local') {
  baseApiUrl = 'http://localhost:3000/api';
}

if (ENV === 'debug') {
  baseApiUrl = 'https://garciavalter.ngrok.dev/api';
}

if (ENV === 'development') {
  baseApiUrl = 'https://dev.shareittapp.com/api';
}

if (ENV === 'production') {
  baseApiUrl = 'https://prod.shareittapp.com/api';
}

export const BASE_API_URL = baseApiUrl;
