// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.SortableGroupsGrid_sortableGrid__16cC- {\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  grid-gap: 1rem;\n}\n\n.SortableGroupsGrid_itemInfoTitle__2DgNj {\n  color: #189BC9;\n  position: relative;\n}\n\n.SortableGroupsGrid_itemIcon__2HFSH {\n  box-sizing: content-box;\n  fill: #A44AFE !important;\n  stroke: #A44AFE !important;\n}\n\n[dir] .SortableGroupsGrid_itemIcon__2HFSH {\n  border-radius: 50%;\n}\n\n.SortableGroupsGrid_sortableGridItemWrapper__380iq {\n  height: auto;\n}\n\n[dir] .SortableGroupsGrid_sortableGridItemWrapper__380iq {\n  background-color: #F5F6F7 !important;\n  border-top-width: 1px !important;\n}\n.SortableGroupsGrid_sortableGridItemWrapper__380iq.SortableGroupsGrid_deactivatedItem__2jQIZ > *:not(.SortableGroupsGrid_sortableGridItemContent__1UkDx) {\n  opacity: 0.5;\n}\n.SortableGroupsGrid_sortableGridItemWrapper__380iq:hover {\n  transition: all 0.2s ease-in-out;\n}\n[dir] .SortableGroupsGrid_sortableGridItemWrapper__380iq:hover {\n  background-color: #e7e9ec !important;\n  cursor: pointer;\n}", ""]);
// Exports
exports.locals = {
	"sortableGrid": "SortableGroupsGrid_sortableGrid__16cC-",
	"itemInfoTitle": "SortableGroupsGrid_itemInfoTitle__2DgNj",
	"itemIcon": "SortableGroupsGrid_itemIcon__2HFSH",
	"sortableGridItemWrapper": "SortableGroupsGrid_sortableGridItemWrapper__380iq",
	"deactivatedItem": "SortableGroupsGrid_deactivatedItem__2jQIZ",
	"sortableGridItemContent": "SortableGroupsGrid_sortableGridItemContent__1UkDx"
};
module.exports = exports;
